.sub-categories-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    /* text-transform: uppercase; */
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.sub-categories-badge.active-active {
    background-color: #C8E6C9;
    color: #256029;
}

.sub-categories-badge.active-not-active {
    background-color: #FFCDD2;
    color: #C63737;
}